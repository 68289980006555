<template>
    <div class="video-list-box">
        <div class="container">
            <!-- 标签页 -->
            <ul class="video-list-tabs clearfix">
                <li v-for="item in video_type"
                    :key="item.id">
                    <a href="javascript:;"
                        :class="{'active-tabs': activeName == item.id}"
                        @click="handleTabs(item.id)">
                        {{item.name}}
                    </a>
                </li>
            </ul>
            <!-- 内容 -->
            <div class="video-list-content">
                <el-row :gutter="20">
                    <el-col :span="8" v-for="(item, index) in list" :key="index">
                        <router-link :to="{name: 'videoDetail', query: {id: item.id}}">
                            <el-card :body-style="{ padding: '0px' }" shadow="hover">
                                <!-- 图片区域 -->
                                <div class="video-list-pic-box">
                                    <img :src="item.cover_map" alt="">
                                </div>
                                <!-- 信息区域 -->
                                <div class="video-list-info">
                                    <!-- 标题 -->
                                    <div class="video-list-title">{{item.title}}</div>
                                    <div class="video-list-time-box">
                                        <span>
                                            <i class="el-icon-time"></i>
                                            2019-08-02
                                        </span>
                                        <!-- 观看次数 -->
                                        <span>
                                            <i class="el-icon-view"></i>
                                            {{item.hot}}
                                        </span>
                                    </div>
                                </div>
                            </el-card>
                        </router-link>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            activeName: "",
        }
    },
    computed: mapState({
        list: state => state.goodsList.list,
        video_type: state => state.goodsList.video_type,
    }),
    methods: {
        ...mapActions([
            "getVideoList"
        ]),
        setActive(val){
            if(val.length>0){
                val.filter((val, index) => {
                    if(index == 0){
                        this.activeName = val.id;
                        this.getVideoList(val.id)
                    }
                })
            }
        },
        handleTabs(id){
            this.getVideoList(id);
            this.activeName = id;
        },
    },
    watch: {
        video_type: {
            handler: "setActive",
            immediate: true
        }
    },
}
</script>

<style scoped>
/* 容器样式 */
.video-list-box{
    padding: 100px 0;
}

/* 标签页 */
.video-list-tabs>li{
    float: left;
    margin-right: 10px;
}
.video-list-tabs>li>a{
    display: block;
    padding: 10px 20px;
    background-color: #8d9afc;
    color: #fff;
    font-size: 18px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.video-list-tabs>li>a.active-tabs{
    background-color: #fff;
    color: #303133;
}

/* 内容 */
.video-list-content{
    padding: 35px;
    background-color: #fff;
    border-radius: 10px;
    border-top-left-radius: 0;
}
.video-list-content .el-col{
    margin-bottom: 20px;
}
/* 图片 */
.video-list-pic-box{
    position: relative;
    width: 100%; 
    overflow: hidden;
    background-color: #f5f7fa;
}
.video-list-pic-box::before{
    display: block;
    content: "";
    padding-top: 60%;
}
.video-list-pic-box>img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}
/* 信息部分 */
.video-list-info{
    width: 100%;
    padding: 15px;
}
.video-list-title{
    /* text-align: center; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.video-list-title:hover{
    color: #8d9afc;
}
.video-list-time-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.video-list-time-box>span{
    color: #909399
}
</style>